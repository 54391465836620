import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Chat,
    Subtitles,
    useStateValue,
    pubnub,
    TextToSpeech,
    SpeechToText,
    MainWrapper,
} from 'wwtc-sdk';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    signedEndpoint,
} from '../constants/connection';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const { userName, language, gender, roomName, apiToken, logoHeader } = history.location
        .state || {
        userName: '',
        language: '',
        gender: '',
        roomName: 'default', // default name
        apiToken: null,
        logoHeader: null,
    };

    useEffect(() => {
        if (userName === '') {
            history.push(`/`, {
                userName: userName,
                language: language,
                gender: gender,
            });
        }
    }, []);

    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={roomName}
            userName={userName}
        >
            <Header logoHeader={logoHeader} pubnub={pubnub} />
            <WrapperComponents apiToken={apiToken} />
            {/* <Footer /> */}
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const { logoHeader, pubnub, initName } = props;

    const handleLogout = () => {
        if (pubnub) {
            pubnub.unsubscribeAll();
            window.location.replace(window.location.origin);
        } else {
            window.location.replace(window.location.origin);
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${logoHeader}`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>NOW Talk and Text</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function Footer() {
    const [{ subtitles, chat }, dispatch] = useStateValue();

    const handleSubtitles = () => {
        if (subtitles.visible) {
            dispatch({
                type: 'SUBTITLES_changeVisible',
                value: false,
            });
        } else {
            dispatch({
                type: 'SUBTITLES_changeVisible',
                value: true,
            });
        }
    };

    const handleChat = () => {
        if (chat.visible) {
            dispatch({
                type: 'CHAT_changeVisible',
                value: false,
            });
        } else {
            dispatch({
                type: 'CHAT_changeVisible',
                value: true,
            });
        }
    };

    return (
        <footer>
            <div className="footer-item-btn">
                <button
                    onClick={handleChat}
                    className={`btn btn-flex btn-sm ${
                        chat.visible ? 'Button-primary' : 'btn-default'
                    }`}
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Chat"
                    style={{ fontSize: 16 }}
                >
                    <i className="fas fa-comments"></i>&nbsp;
                    <label>CHAT</label>
                </button>
            </div>
            <div className="footer-item-btn">
                <button
                    onClick={handleSubtitles}
                    className={`btn btn-flex btn-sm ${
                        subtitles.visible ? 'Button-primary' : 'btn-default'
                    }`}
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Subtitles"
                    style={{ fontSize: 16 }}
                >
                    <i className="fas fa-closed-captioning"></i>&nbsp;
                    <label>SUBTITLES</label>
                </button>
            </div>
        </footer>
    );
}

function WrapperComponents(props) {
    // values from autologin
    const { apiToken, initName, initLanguage, initGender, conferenceName } = props;
    const [{ chat, subtitles }] = useStateValue();

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        try {
            let response = await fetch(endpoint.url.replace(/:sourceLanguage/g, sourceLanguage), {
                method: endpoint.method,
                redirect: 'follow',
                body: JSON.stringify({ text }),
            });
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const stylesChat = subtitles.visible
        ? stylesWrapper.chatContainer
        : stylesWrapper.fullContainer;
    return (
        <div className="wrapper-components">
            <Chat.ChatUI
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage}
                initialVisible={true}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                speechRecognition={true}
                channel={conferenceName}
                showButtonCloseComponent={false}
                style={{ ...stylesChat, background: '#transparent' }}
            />
            <Subtitles.SubtitlesUI
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage}
                initialVisible={true}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                ttsOption={true}
                showInput={false}
                showButtonCloseComponent={false}
                channel={conferenceName}
                style={
                    chat.visible ? stylesWrapper.subtitlesContainer : stylesWrapper.fullContainer
                }
            />
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={signedEndpoint(apiToken, endpointSpeechToText)}
            />
            <TextToSpeech.TextToSpeechUI
                initialVendor="readspeaker"
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={signedEndpoint(apiToken, endpointTextToSpeech)}
                fetchCustom={fetchTextToSpeechCustom}
            />
        </div>
    );
}

const stylesWrapper = {
    chatContainer: {
        width: '100%',
        height: '50%',
        margin: '0',
        borderRadius: 'none',
        boxShadow: 'none',
    },
    subtitlesContainer: {
        width: '100%',
        height: 'calc(50% - 10px)',
        marginTop: '10px',
        background: 'transparent',
        boxShadow: 'none',
    },
    fullContainer: {
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 20px)',
        margin: '10px auto',
    },
};

export default Main;
